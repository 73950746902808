import { useAuthStore } from '../../_base/store/auth';

export async function $getSession() {
    const authStore = useAuthStore();
    const config = useRuntimeConfig();

    if (import.meta.client) {
        const identity = Math.floor(Math.random() * 255).toString();

        console.log('Requesting Session');
        useFetch(`${process.dev ? '/proxy' : ''}/session`, {
            // @ts-ignore
            onResponse({ response }) {
                if (response && response.ok) {
                    console.log('Received Session');
                    authStore.update(response?._data);
                }
            },
            onResponseError({ error }) {
                if (error) {
                    console.log('Failed to get Session');
                    console.error(error);
                    if (config?.public?.STORYBLOK_VERSION === 'draft') {
                        const newDiv: any = document.createElement('div');
                        const newContent: any = document.createTextNode('Session Failed to Load');
                        newDiv.classList.add('session-notify');
                        newDiv.classList.add('id-' + identity);
                        newDiv.classList.add('open');
                        newDiv.classList.add('!bg-red-500');
                        // @ts-ignore
                        newDiv.appendChild(newContent);
                        const currentDiv = document.getElementById('__nuxt');
                        document.body.insertBefore(newDiv, currentDiv);
                    }
                }
            },
        });
    }

    return;
}
